import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { getS3Url, MediaOpts } from './get-s3-url';

export type S3ImgProps = {
  path?: string;
} & MediaOpts &
  Omit<ComponentPropsWithoutRef<'img'>, 'src'>;
export const S3Img = forwardRef<HTMLImageElement, S3ImgProps>(function S3Img(
  { path, w, h, rs, ...rest },
  ref,
) {
  const url = getS3Url(path, { w, h, rs });

  return <img ref={ref} src={url?.toString()} {...rest} />;
});
